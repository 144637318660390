export const team_members = [
    {
        name: "Seth",
        status: "CEO",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim dui, consequat ac ultricies vitae, bibendum non turpis. Pellentesque tincidunt purus ut malesuada gravida. ",
        email: "mailto:test_email@gmail.com",
        phone: "tel:1234564565632"
    },
    {
        name: "Harry",
        status: "CEO",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim dui, consequat ac ultricies vitae, bibendum non turpis. Pellentesque tincidunt purus ut malesuada gravida. ",
        email: "mailto:test_email@gmail.com",
        phone: "tel:1234564565632"
    },
    {
        name: "Alina",
        status: "CEO",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim dui, consequat ac ultricies vitae, bibendum non turpis. Pellentesque tincidunt purus ut malesuada gravida. ",
        email: "mailto:test_email@gmail.com",
        phone: "tel:1234564565632"
    },
    {
        name: "Alex",
        status: "CEO",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim dui, consequat ac ultricies vitae, bibendum non turpis. Pellentesque tincidunt purus ut malesuada gravida. ",
        email: "mailto:test_email@gmail.com",
        phone: "tel:1234564565632"
    },
    {
        name: "Steve",
        status: "CEO",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim dui, consequat ac ultricies vitae, bibendum non turpis. Pellentesque tincidunt purus ut malesuada gravida. ",
        email: "mailto:test_email@gmail.com",
        phone: "tel:1234564565632"
    },
    {
        name: "Ron",
        status: "CEO",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed enim dui, consequat ac ultricies vitae, bibendum non turpis. Pellentesque tincidunt purus ut malesuada gravida. ",
        email: "mailto:test_email@gmail.com",
        phone: "tel:1234564565632"
    }
]