import React , {useEffect} from 'react'
//Importing AOS Animations
import AOS from "aos";
import "aos/dist/aos.css";


function onceHub() {
  return (
   <>
    <button id="SOIBTN_SalesTeamPage-3ME9RBKLZA" style="background: #006DAF; color: #ffffff; padding: 10px 20px; border: 1px solid #c8c8c8; font: bold 14px Arial; cursor: pointer;" data-height="580" data-psz="00" data-so-page="SalesTeamPage-3ME9RBKLZA" data-delay="1">Schedule an Appointment</button>
   </>
  )
}

export default onceHub
